@each $color, $value in $colors {
    .text-#{$color}, a.text-#{$color}, .text-#{$color} {
        color: $value !important;
    }
    a.text-#{$color}:hover {
        color: darken($value, 15%) !important;
    }
}


.hidden {
    display: none !important;
}

.init-hide {
    // Not !important so that jQuery can display it later.
    display: none;
}


.form-errors {
    margin: 0.5rem 0;
}

.form-hidden {
    display: block;
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0;
}

.full-width {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}


.form-group {
    &.has-error {
        ul {
            li {
                padding: 0.2rem 0.7rem;
                margin-bottom: 0;

                &:first-child {
                    padding-top: 0.7rem;
                }
            }
        }
    }
}


.fill-100 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

img.fill-100 {
    width: 100%;
    height: auto;
}

img.w-100 {
    // IE Fix
    min-height: 1px;
}


.img-dark {
    background-color: rgba(0, 0, 0, 0.4);
}


.prevent-select {
    user-select: none;
}


.no-shadow {
    text-shadow: none !important;
    box-shadow: none !important;
}

input.transparent {
    background-color: transparent;
    border: none;
}


.show-more {
    display: flex;
    justify-content: flex-end;

    a {
        color: $body-color;
    }
}


.image-round {
    img {
        border-radius: 100%;
        border: 2px solid white;
    }
}

.bar-shaded {
    background: white;
    box-shadow: 0 0 0.35rem rgba(0, 0, 0, 0.1);

    img {
        margin: 0.5rem 0.5rem 0.5rem 0;
    }
}

.bar-flex {
    display: flex;
    align-items: center;
}


.container-narrow {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
}

.expand-mobile {
    @include media-breakpoint-down(sm) {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
    }
}

.help-text {
    color: $gray-700;
    font-size: 0.8rem;
    line-height: 1.0rem;
    margin-top: 0.2rem;
}


.form-control {
    &.textarea-small {
        height: 2.5rem;
    }
}


.autosize {
    max-height: 12rem;
}


%removeChildMargin {
    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }
}


.background-gradient {
    background: linear-gradient(90deg, adjust_hue($primary, -5) 0%, adjust_hue($primary, 5) 100%);
}


.bg-alternate {
    background-color: $gray-100;
}

.bg-invert {
    background-color: $primary;
    color: $white;

    h1, h2, h3, h4, h5, h6 {
        color: $white;
    }
}

.form-control-label .required {
    color: $primary;
}

.form-info {
    @extend .small;
    color: $text-muted;
}
