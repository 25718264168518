
header {
    @extend .background-gradient;

    flex-grow: 0;


    &.transparent {
        background: transparent;
    }

    .logo {
        margin: 1rem 0;
    }
}

// Main

main {
    flex-grow: 1;

    .container {
        @extend %removeChildMargin;
    }
}

.main-padding {
    padding: 5rem 0;
}

// Footer

footer {
    flex-grow: 0;

    padding: 1rem 0;

    font-size: 0.8rem;

    background-color: $gray-200;


    h4 {
        font-size: 1.1rem;
        padding-bottom: 0.9rem;
    }

    a {
        color: $gray-600;
    }
}
