/**
 * Fontawesome
 */
$fa-font-path: "../../../fonts/fontawesome";
@import "vendor/fontawesome/fontawesome";
//@import "vendor/fontawesome/solid";
@import "vendor/fontawesome/regular";
//@import "vendor/fontawesome/light";
//@import "vendor/fontawesome/brands";


/**
 * Local fonts
 */
$fonts-dir: "../fonts/";

@include font-face("Roboto", $fonts-dir + "roboto-v30-latin/roboto-v30-latin-regular", 400, null, eot woff woff2 svg ttf);
@include font-face("Roboto", $fonts-dir + "roboto-v30-latin/roboto-v30-latin-700", 700, null, eot woff woff2 svg ttf);

