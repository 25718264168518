html {
    position: relative;
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    height: 100%;
    min-width: 320px;
    display: flex;
    flex-direction: column;

    margin-bottom: 7rem;

    // iPhoneX notch overrides
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $black-font;
}

h1, .h1 {

    &:not(:first-child) {
        margin-top: 3rem;
    }

    //
    //@include media-breakpoint-down(sm) {
    //    font-size: 2.2rem;
    //}
}

h2, .h2 {

    &:not(:first-child) {
        margin-top: 2rem;
    }

    font-weight: $font-weight-bold;

    //
    //@include media-breakpoint-down(sm) {
    //    font-size: 1.8rem;
    //    line-height: 2.2rem;
    //}
}

h3, .h3 {

    &:not(:first-child) {
        margin-top: 1.5rem;
    }
}

h4, .h4 {
    &:not(:first-child) {
        margin-top: 0.5rem;
    }
}

h5, .h5 {
    margin-bottom: 0.25rem;

    &:not(:first-child) {
        margin-top: 0.5rem;
    }
}

h6, .h6 {
    margin-bottom: 0.25rem;

    &:not(:first-child) {
        margin-top: 0.5rem;
    }
}

p, td, th {
}

button {
    border: none;
}

a {
    &:hover {
    }
}


strong, b {
    font-weight: 700;
}


*:focus {
    outline: none;
}


iframe {
    width: 100%;
    border: none;
    box-shadow: $box-shadow-sm;
}

small, .small {
    font-size: 0.8rem;
    line-height: 1.1rem;
}

button {
    background-color: transparent;
}
