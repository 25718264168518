
.custom-file-label {
    overflow: hidden;
}

.modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
    @supports (backdrop-filter: blur(3px)) {
        backdrop-filter: blur(3px);
        background: rgba(0, 0, 0, 0.3);
    }

    &.show {
        opacity: 1;
    }

}


.modal-invert {
    .modal-content {
        background-color: rgba($random-encounter-bg, 0.95);
        color: $random-encounter-color;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
            color: $random-encounter-color;
        }

        .btn.btn-outline-light:hover {
            color: $random-encounter-bg;
        }
    }

    .text-muted {
        color: $gray-400 !important;
    }
}


.modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .close {
        padding: 0;
        font-size: $font-size-base;
        margin: 0;
        line-height: 1.5;
    }
}

.modal-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.form-group {
    label {
        margin-bottom: 0.25rem;
    }
}

.card {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05)
}
