/**
 * Colors
 */


$blue: rgb(0, 175, 238);
$red: rgb(204, 0, 0);

$blue: #00737E;



$black: rgb(45, 45, 45);
$black-font: rgb(45, 45, 45);

$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #ebebeb;
$gray-300: #e1e1e1;
$gray-400: #d4d4d4;
$gray-500: rgb(190, 190, 190);
$gray-600: #919191;
$gray-700: #494949; // Main Text color!!!
$gray-800: #3f3f3f;
$gray-900: #242424;


$gray: $gray-500;

$primary: $blue;
$secondary: $gray-500;


// Add colors
$colors: (
    "black": $black,
    "gray": $gray,
    "white": $white,
);

$theme-colors: (
    "gray": $gray,
    "white": $white,
    "error": $red
);

$body-bg: $gray-100;
$random-encounter-color: $white;
$random-encounter-bg: $gray-800;

$font-family-sans-serif: "Roboto", sans-serif;

$headings-font-weight: bold;
$headings-color: $gray-800;
$body-color: $black-font;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`


$close-font-size: 2.5rem;
$close-font-weight: 300;

$link-decoration: none;
$link-hover-decoration: none;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .5),
    2: ($spacer * 1),
    3: ($spacer * 2),
    4: ($spacer * 3),
    5: ($spacer * 4),
    6: ($spacer * 6),
    7: ($spacer * 8),
    8: ($spacer * 10),
    9: ($spacer * 12),
    10: ($spacer * 15)
);

$border-radius: .20rem;
$border-radius-lg: .30rem;
$border-radius-sm: .15rem;

$border-width: 1px;
$border-color: $gray-200;
$standard-border: $border-width solid $border-color;

$input-btn-border-width: 1px;
$input-btn-padding-x: 1.5rem;


$form-group-margin-bottom: 1.5rem;

$modal-inner-padding: 1.5rem;
$modal-header-padding: 1.5rem;

$mobile-menu-breakpoint: sm;


$dropdown-box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);

$dropdown-border-color: $gray;

$list-separator: $border-width solid $border-color;
