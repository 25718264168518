
.select2 *,
.select2-container * {
    user-select: none;
}

.select2-search__field {
    user-select: auto !important;
}

.select2-results__option {
    min-height: 36px;
}

.select2 {
    ul {
        li {
            margin: 0;
            padding-right: 0.5rem !important;

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }
    }
}
